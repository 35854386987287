<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import Approval from './components/approval.vue';
import CancelRespVos from './components/table.vue';

formCreate.component('Approval', Approval);
formCreate.component('CancelRespVos', CancelRespVos);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'sfa_attendance_business_trip_application_detail',
      formParentCode: 'CRM20201230000000277',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    this.getDetail();
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'cancelRespVos') { // 选择客户
        item.value = [];
      }
      return item;
    },
    // 获取详情
    getDetail() {
      const requestUrl = '/sfa/sfaSignFormsController/queryTravel';
      request.post(requestUrl, this.formConfig.row.id || this.id).then((res) => {
        if (res.success) {
          const { result } = res;
          const cancelRespVos = this.getRule('cancelRespVos');
          cancelRespVos.value = result.cancelRespVos || [];
          if (result.travelType === 'temp_travel') {
            result.timeList = result.timeInfoListJson ? JSON.parse(result.timeInfoListJson) : [];
            const { length } = result.timeList;
            if (length > 0) {
              const startTimeType = result.timeList[0].timeType;
              const startTimeStr = result.timeList[0].timeStr;
              const endTimeType = result.timeList[length - 1].timeType;
              const endTimeStr = result.timeList[length - 1].timeStr;
              if (startTimeType === '3') {
                result.beginTime = `${startTimeStr} 下午`;
              } else if (startTimeType === '2') {
                result.beginTime = `${startTimeStr} 上午`;
              } else {
                result.beginTime = `${startTimeStr} 全天`;
              }
              if (endTimeType === '2') {
                result.endTime = `${endTimeStr} 上午`;
              } else if (endTimeType === '3') {
                result.endTime = `${endTimeStr} 下午`;
              } else {
                result.endTime = `${endTimeStr} 全天`;
              }
            // result.beginTime = startTimeType === '3' ? `${startTimeStr} 下午` : startTimeType === '2' ? `${startTimeStr} 上午` : `${startTimeStr} 全天`;
            // result.endTime = endTimeType === '2' ? `${endTimeStr} 上午` : endTimeType === '3' ? `${endTimeStr} 下午` : `${endTimeStr} 全天`;
            }
          }
          this.setValue(result);
        }
      });
    },
  },
};
</script>
