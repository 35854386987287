<template>
  <div>
    <div class="item" v-for="(item, index) in list" :key="index">
      <el-row>
        <el-col :span="12">审批人姓名：王丽丽</el-col>
        <el-col :span="12">
          <div class="color-blue">
            <span>审批通过</span>
            <span class="pl-20">2020/08/31 09:09:23</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">意见：情况属实，通过。</el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="24" class="pl-20 pt-20">审批状态：审批中</el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      list: [{}, {}, {}],
    };
  },
};
</script>
<style lang="less" scoped>
.item{
  padding: 20px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  .color-blue{
    color: #409EFF;
  }
}
.pl-20{
  padding-left: 20px;
}
.pt-20{
  padding-top: 20px;
}
</style>
