var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        { attrs: { data: _vm.tableData } },
        [
          _c("vxe-table-column", {
            attrs: { title: "出差确认开始时间", field: "startTime" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "出差确认结束时间", field: "endTime" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "审批状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.bpmStatus == 1
                      ? _c("span", [_vm._v("审批中")])
                      : _vm._e(),
                    row.bpmStatus == 3
                      ? _c("span", [_vm._v("审批通过")])
                      : _vm._e(),
                    row.bpmStatus == 4
                      ? _c("span", [_vm._v("审批驳回")])
                      : _vm._e(),
                    row.bpmStatus == 5
                      ? _c("span", [_vm._v("流程追回")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }