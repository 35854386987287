var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "item" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _vm._v("审批人姓名：王丽丽"),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "color-blue" }, [
                    _c("span", [_vm._v("审批通过")]),
                    _c("span", { staticClass: "pl-20" }, [
                      _vm._v("2020/08/31 09:09:23"),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _vm._v("意见：情况属实，通过。"),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "pl-20 pt-20", attrs: { span: 24 } }, [
            _vm._v("审批状态：审批中"),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }